<template>
  <AppLoginPageLayout>
    <div class="d-flex justify-center">
      <img
        src="@/assets/icons/logo_sourcery.svg">
    </div>
    <h1 class="form-login__container--title">
      Reset your password
    </h1>
    <p class="form-login__container--description">
      Create your new password below
    </p>
    <v-form @submit.prevent="confirmResetPassword">
      <v-text-field
        v-model="password.newPassword"
        placeholder="New Password"
        single-line
        :type="showNewPassword ? 'text' : 'password'"
        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
        filled
        dense
        :error-messages="newPasswordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!newPasswordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @click:append="showNewPassword = !showNewPassword" />
      <v-text-field
        v-model="password.repeatPassword"
        placeholder="Confirm password"
        single-line
        :type="showConfirmPassword ? 'text' : 'password'"
        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
        filled
        dense
        :error-messages="repeatPasswordErrors"
        prepend-inner-icon="mdi-lock-outline"
        :error="!!repeatPasswordErrors.length"
        class="mt-6"
        autocomplete="new-password"
        @click:append="showConfirmPassword = !showConfirmPassword" />
      <SignUpAgreeTermsConditions
        :agree-terms-conditions.sync="agreeTermsConditions" />
      <v-row class="d-flex justify-center mt-12">
        <v-btn
          :disabled="isResetBtnDisabled"
          type="submit"
          color="lightBlue">
          RESET PASSWORD
        </v-btn>
      </v-row>
    </v-form>
  </AppLoginPageLayout>
</template>
<script>
import {
  required,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators';
import { Auth } from 'aws-amplify';
import AppLoginPageLayout from '@/components/App/AppLoginPageLayout';
import { mapMutations } from 'vuex';
import SignUpAgreeTermsConditions from '@/components/SignUp/SignUpAgreeTermsConditions';
import ErrorsText from '@/constants/errors';
import API from '@/services/graphql';
export default {
  name: 'CompleteNewPassword',
  components: {
    AppLoginPageLayout,
    SignUpAgreeTermsConditions,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    errorWithCode: null,
    showNewPassword: false,
    showConfirmPassword: false,
    agreeTermsConditions: false,
    password: {
      newPassword: null,
      repeatPassword: null,
    },
  }),
  validations: {
    password: {
      newPassword: {
        required, minLength: minLength(8),
      },
      repeatPassword: {
        required, sameAs: sameAs('newPassword'),
      },
    },
  },
  computed: {
    verificationCodeErrors() {
      const errors = [];
      if (!this.$v.password.code.$dirty) return errors;
      if (!this.$v.password.code.minLength) {
        errors.push('Verification code must be at 6 characters long');
      }
      if (!this.$v.password.code.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.errorWithCode) {
        errors.push(this.errorWithCode);
      }
      return errors;
    },
    isResetBtnDisabled() {
      return !this.agreeTermsConditions || !this.password.newPassword || !this.password.repeatPassword;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.password.newPassword.$dirty) return errors;
      if (!this.$v.password.newPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.newPassword.minLength) {
        errors.push('Password must be with 8 characters long');
      }
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.password.repeatPassword.$dirty) return errors;
      if (!this.$v.password.repeatPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.repeatPassword.sameAs) {
        errors.push('Confirm Password must be the same');
      }
      return errors;
    },
  },
  created() {
    if (!this.user) {
      this.$router.push('login');
    }
  },
  methods: {
    ...mapMutations(['spinner']),
    async confirmResetPassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        this.spinner(true);
        try {
          await Auth.completeNewPassword(
            this.user,
            this.password.newPassword,
            this.user.challengeParam.requiredAttributes,
          );
          const { query } = this.$route;
          await API.acceptTermsOfService();
          await API.verifyEmail();
          if (query && query.hasOwnProperty('forcepay')) {
            this.$router.push({
              name: 'payment', query,
            });
          } else {
            this.$router.push({
              name: 'projects',
            }).catch(() => {
            });
          }
        } catch (err) {
          console.log('err', err);
          this.spinner(false);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
